import React from "react";
import Image from "next/image";

function HeaderSection() {
  return (
    <div className="bg-light">
      <div className="container">
        {/* navbar section */}
        <nav className="navbar navbar-expand-lg bg-white py-3 fixed-top w-100 shadow-sm">
          <div className="container-fluid ">
            <a
              id="header-homeimg-link"
              className="navbar-brand pe-5 linker text-dark link-secondary w-25"
              href="#"
            >
              <img
                style={{objectFit: "contain"}}
                className="logo-default h-25px h-lg-30px"
                src="/assets/mdr-logo/mdrlogo.png"
                width={150}
                height={40}
                alt="mdr2.1logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-center">
                <li className="nav-item px-4">
                  <a
                    id="header-home-link"
                    className="nav-link fw-semibold linker text-dark link-secondary"
                    aria-current="page"
                    href="#"
                  >
                    <i
                      className="fa fa-lg fa-light fa-house"
                      style={{ color: "#2a3d50" }}
                    ></i>{" "}
                    Beranda
                  </a>
                </li>
                <li className="nav-item px-4">
                  <a
                    id="header-map-link"
                    className="nav-link fw-semibold linker text-dark link-secondary"
                    href="#map-section"
                  >
                    <i
                      className="fa fa-lg fa-light fa-map-location-dot"
                      style={{ color: "#2a3d50" }}
                    ></i>{" "}
                    Maps
                  </a>
                </li>
                <li className="nav-item px-4">
                  <a
                    id="header-statistic-link"
                    className="nav-link fw-semibold linker text-dark link-secondary"
                    href="#statistic-section"
                  >
                    <i
                      className="fa fa-lg fa-light fa-chart-simple"
                      style={{ color: "#2a3d50" }}
                    ></i>{" "}
                    Statistik
                  </a>
                </li>
                <li className="nav-item px-4">
                  <a
                    id="header-gallery-link"
                    className="nav-link fw-semibold linker text-dark link-secondary"
                    href="#gallery-section"
                  >
                    <i
                      className="fa fa-lg fa-regular fa-images"
                      style={{ color: "#2a3d50" }}
                    ></i>{" "}
                    Galeri
                  </a>
                </li>
                <li className="nav-item px-4">
                  <a
                    className="nav-link fw-semibold linker text-dark link-secondary"
                    href="https://datamigas.esdm.go.id/docsviewer/DocumentView.aspx?filename=termandco" target="_blank"
                  >
                    <i
                      className="fa fa-lg fa-light fa-circle-info"
                      style={{ color: "#2a3d50" }}
                    ></i>{" "}
                    Regulasi
                  </a>
                </li>
              </ul>
              <a
                type="button"
                href="https://datamigas.esdm.go.id"
                target="_blank"
                className="btn btn-warning fw-semibold shadow-sm"
                id="loginBtn"
              >
                <i className="fa fa-lg fa-sign-in"></i> Log In Portal
              </a>
            </div>
          </div>
        </nav>
        {/* end of navbar section */}
      </div>
    </div>
  );
}

export default HeaderSection;
