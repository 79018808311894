
function FooterSection() {
  const copyYear = new Date().getFullYear();
  return (
    <>
  {/* footer section */}
  {/* <div style={{"width": "100%", "height":"150px"}} className="section-styler">&nbsp;</div> */}
  <footer id="footer">
    <div className="container">
      <div className="p-3">&nbsp;</div>
      <div className="d-flex justify-content-between">
        <div className="footer-section-divider text-white">
          <div>
            <img
              className='d-block logo-footer'
              src="/assets/mdr-logo/mdrlogo.png"
              height={60}
              alt="mdr2.2logo"
            />
            <span className="fw-bold fst-italic">MDR</span> | Migas Data
            Repository
          </div>
          <div className="m-3">&nbsp;</div>
          <div className="w-100">
              <img src="https://pse.layanan.go.id/api/storage/badge/badge_2168.png" style={{width:"10rem", height:"3rem"}} width="0" height="0" alt="badge-pse-mdr" />
          </div>
        </div>
        <div className="footer-section-divider">
          <h5 className="fw-bold">Landing Page</h5>
          <div className="m-3">&nbsp;</div>
          <a id="footer-home-link" href="#">
            <p>Beranda</p>
          </a>
          <a id="footer-maps-link" href="#map-section">
            <p>Maps</p>
          </a>
          <a id="footer-statistic-link" href="#statistic-section">
            <p>Statistik</p>
          </a>
          <a id="footer-gallery-link" href="#gallery-section">
            <p>Galeri</p>
          </a>
        </div>
        <div className="footer-section-divider">
          <h5 className="fw-bold">Layanan Publik</h5>
          <div className="m-3">&nbsp;</div>
          <a href="https://www.esdm.go.id/wkmigas/" target='_blank'>
            <p>E-Lelang WK</p>
          </a>
          <a href="https://geologi.esdm.go.id/geomap" target='_blank'>
            <p>Geo-Migas PSG</p>
          </a>
          <a href="https://www.skkmigas.go.id/" target='_blank'>
            <p>Investment Opportunity</p>
          </a>
          <a href="https://geoportal.esdm.go.id/" target='_blank'>
            <p>Geo-Portal</p>
          </a>
        </div>
      </div>
      <hr className="text-white" />
      <div className="d-flex align-items-center justify-content-between text-white">
        <h6>All rights reserved copyright © {copyYear}</h6>
        <h6>
            Hubungi Kami: <a href="mailto:helpdesk.datamigas@esdm.go.id" className="fst-italic emailfont">helpdesk.datamigas@esdm.go.id</a>
          </h6>
        </div>
    </div>
  </footer>
  {/* end of footer section */}
</>

  )
}

export default FooterSection