"use client";
import dynamic from "next/dynamic";
import CarouselSection from "@/component/CarouselSection";
import InterlinkSection from "@/component/InterlinkSection";
import DataStatisticSection from "@/component/DataStatisticSection";
import FooterSection from "@/component/FooterSection";
import Script from "next/script";
import HeaderSection from "@/component/HeaderSection";
import LinkScriptImporter from "@/component/LinkScriptImporter";
import Head from "next/head";
import { insertVisitorCounter } from "@/lib/visitor_counter";
import { getAllInitData } from "@/lib/get_all_data";
import react, {useEffect, useState} from "react";
const GallerySection = dynamic(() => import("@/component/GallerySection"), {
  loading: () => <p>Loading...</p>,
  ssr: true
});
const MapSection = dynamic(() => import("@/component/MapSection"), {
  loading: () => <p>Loading...</p>,
  ssr: true
});

export async function getServerSideProps(ctx) {
  try {

    //ssr for visitor counter
    insertVisitorCounter();
    //ssr fog image slider
    const reqImgSlide = await fetch(
      `${process.env.BASE_URL_API_TARGET}${process.env.API_SLIDER_IMAGE}`
    );
    const data = await reqImgSlide.json();
    const sliderLinkHref = [];
    const imgLinks = [];
    data.data.forEach((element) => {
      imgLinks.push(
        `${process.env.BASE_URL_API_TARGET}` +
          element.attributes.picture.data.attributes.url
      );
      sliderLinkHref.push(element.attributes.link);
    });

    //ssr for images new data coming
    const reqNewComing = await fetch(
      `${process.env.BASE_URL_API_TARGET}${process.env.API_NEW_COMING_DATA}`
    );
    const dataNewComing = await reqNewComing.json();

    const newComingDataLinks = [];
    dataNewComing.data.forEach((element) => {
      newComingDataLinks.push(
        `${process.env.BASE_URL_API_TARGET}` +
          element.attributes.images.data[0].attributes.url
      );
    });

    const MultiFetchData = await getAllInitData();
    const dataDigitalStatistic = await MultiFetchData[0].json();
    const dataPhysicalStat = await MultiFetchData[1].json();
    const dataLinkYt = await MultiFetchData[2].json();
    const dataCollectionDates = await MultiFetchData[3].json();
    const dataSplashText = await MultiFetchData[4].json();
    const dataChart = await MultiFetchData[5].json();

    return {
      props: {
        dataCollectionDates,
        imgLinks,
        sliderLinkHref,
        newComingDataLinks,
        dataLinkYt: dataLinkYt.data,
        dataPhysicalStat: dataPhysicalStat.data,
        dataSplashText: dataSplashText.data,
        dataChart: dataChart.data,
        GIS_WELL_API: process.env.GIS_WELL_API,
        GIS_SEIS2D_API: process.env.GIS_SEIS2D_API,
        GIS_SEIS3D_API: process.env.GIS_SEIS3D_API,
        dataDigitalStatistic
      }
    };
  } catch (e) {
    throw e;
  }
}

export default function Home(props) {
  const {
    imgLinks,
    sliderLinkHref,
    newComingDataLinks,
    dataLinkYt,
    dataPhysicalStat,
    dataSplashText,
    dataChart,
    GIS_WELL_API,
    GIS_SEIS2D_API,
    GIS_SEIS3D_API,
    dataCollectionDates,
    dataDigitalStatistic
  } = props;

  const labelChart = dataChart.map((dat) => {
    return dat.attributes.month_name;
  });

  //unique label month
  let uniqueLabelChart = [];
  labelChart.forEach((element) => {
    if (!uniqueLabelChart.includes(element)) {
      uniqueLabelChart.push(element);
    }
  });

  const YearPresent = new Date();
  const ListFilteredYear = () => {
    let TempList = [];
    for (let i = 0; i < 3; i++) {
      TempList.unshift(
        dataChart.filter(
          (val) =>
            val.attributes.year === (YearPresent.getFullYear() - i).toString()
        )
      );
    }
    return TempList;
  };
  //console.log(ListFilteredYear())
  const ListDataSetBar = () => {
    let TempList = [];
    for (let i = 0; i < 3; i++) {
      TempList.push(
        ListFilteredYear()[i].map((dat) => {
          return dat.attributes.data_count;
        })
      );
    }
    return TempList;
  };

  const ListDataCumulative = () => {
    let TempList = [];
    for (let i = 0; i < 3; i++) {
      TempList.push(
        ListDataSetBar()[i].reduce((acc, curr) => {
          const currParseNum = parseInt(curr);
          acc.push(currParseNum + (acc.length ? acc[acc.length - 1] : 0));
          return acc;
        }, [])
      );
    }
    return TempList;
  };
  //console.log(ListDataCumulative())

  let labelToNumber = uniqueLabelChart.map((data, index) => index + 1);

  //get visitor count
  const [visitorCount, setVisitorCount] = useState(0);
  useEffect(() => {
    fetch(`/api/visitor-today`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setVisitorCount(data.attributes.Count);
        });
      }
    })
  },[])

  return (
    <>
      <Head>
        <title>MDR | Migas Data Repository</title>
        <LinkScriptImporter />
      </Head>
      {/* Start Page */}

      <HeaderSection />
      <div className="divider"></div>
      <CarouselSection
        arrCarouselItemImg={imgLinks}
        sliderLinkHref={sliderLinkHref}
      />
      <span style={{ position: 'fixed'  ,zIndex: 600, bottom: 20, left: 'auto', right: 10, margin: 'auto'}}>
        <div className="visitor-card d-flex justify-content-center align-items-center">
          <span id="visitor-badge" className="badge rounded-pill bg-light text-dark text-center" >Visited<br/>today: {visitorCount}</span>
          <img id="visitor-icon" className=" rounded-circle" src="/assets/traveler.png" alt="logo_mdr" width={40} height={40} />
        </div>
        
      </span>

      <InterlinkSection
        arrDataSplashText={dataSplashText}
        newComingDataLinks={newComingDataLinks}
      />

      {/* <NewsSection arrDataNewsContent={dataNewsResult} baseUrl={BASE_URL_API_TARGET}/> */}
      <MapSection
        gisWellApi={GIS_WELL_API}
        gisSeis2DApi={GIS_SEIS2D_API}
        gisSeis3DApi={GIS_SEIS3D_API}
      />

      <DataStatisticSection
        arrDataCollectionDates={dataCollectionDates}
        arrDataPhysicalStat={dataPhysicalStat}
        listDataStatistic={dataDigitalStatistic}
        arrDataChart={dataChart}
      />

      <GallerySection arrDataLinkVidYt={dataLinkYt} />

      <FooterSection />

      {/* End Page */}

      <Script>
        const ListDataSetBarResult = [
          [{ListDataSetBar()[0]}],
          [{ListDataSetBar()[1]}],
          [{ListDataSetBar()[2]}]
        ]; 
        const ListDataCumulativeResult = [
          [{ListDataCumulative()[0]}],
          [{ListDataCumulative()[1]}],
          [{ListDataCumulative()[2]}]
        ];
        
        {`
          const labelChart = [${labelToNumber}].map((data, index) => getMonthName(index+1))
          const idElement= "myChart"
          chartBuildv2(idElement, labelChart, ListDataSetBarResult, ListDataCumulativeResult)
        
        `}
      </Script>
      <Script>
        {`
        AOS.init();
        `}
      </Script>
      <Script>
        {`
         const hoster = window.location.origin
         const loginBtnDom = document.getElementById('loginBtn')
         loginBtnDom.href = hoster+"/Dashboard/Index"
        
        `}
      </Script>
    </>
  );
}
